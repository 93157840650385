<template>
  <Header v-model:search="search" :title="$t('callLog')" />
  <div class="pl-10 pt-5">
    <PeriodSelector
      @timeSelected="(...args) => setDateAndTime(...args)"
      :pbxHosts="pbxHosts"
      @changeHost="getPBXData($event)"
      @openAll="(openAllCalls = true), (closeAllCalls = false)"
      @closeAll="(closeAllCalls = true), (openAllCalls = false)"

    />
  </div>

  <div class="px-10 pt-5">
    <div v-if="filterCallList().length > 0">
      <TablePagination
        :id="openAllCalls"
        rows="10"
        :tableData="filterCallList()"
        :openAllCalls="openAllCalls"
        :closeAllCalls="closeAllCalls"
        @setCloseAllCallsToFalse="
          (closeAllCalls = false), (openAllCalls = false)
        "
      />
    </div>
    <div v-else class="border-2 border-gray-300 border-dashed p-5">
      <div class="text-center">
        <font-awesome-icon
          icon="chart-line"
          class="h-8 w-8 text-gray-400"
          aria-hidden="true"
        />
        <h3 class="mt-2 text-xl font-medium text-gray-900">
          {{ $t("dashboards.error.noDataFoundTitle") }}
        </h3>
        <p class="mt-1 text-base text-gray-500">
          {{ $t("dashboards.error.noDataFoundDescription") }}
        </p>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="true"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
// @ is an alias to /src
import axios from "axios";

import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/vue/solid";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import CallTable from "../../components/PBX/CallTable.vue";
import CallAnalyser from "../../components/PBX/CallAnalyser.vue";
import HistoryTable from "../../components/PBX/HistoryTable.vue";
import TablePagination from "../../components/TablePagination.vue";
import PeriodSelector from "../../components/PBX/PeriodSelector.vue";
import Header from "../../components/PBX/Header.vue";

export default {
  name: "Statistics",
  components: {
    ArrowSmDownIcon,
    ArrowSmUpIcon,
    CallAnalyser,
    CallTable,
    HistoryTable,
    Header,
    Loading,
    TablePagination,
    PeriodSelector,
  },
  data() {
    return {
      openAllCalls: false,
      closeAllCalls: false,
      listOfCalls: [],
      callData: {},
      participants: {},
      callDataHistory: [],
      callDataParticipants: [],
      callDataSegments: [],
      dataFiltered: {},
      hostName: "sfhhz6",
      limit: 1,
      pbxHosts: [],
      roleFilter: "all",
      search: "",
      timeSpan: "",
      isLoading: true,
      fullPage: true,
    };
  },
  methods: {
    setDateAndTime(...args) {
      let year = new Date(args[0]).getFullYear();
      let month = ("0" + (new Date(args[0]).getMonth() + 1)).slice(-2);
      let day = ("0" + new Date(args[0]).getDate()).slice(-2);
      this.timeSpan = String(year) + String(month) + String(day) + args[1];
    },
    sortDateTime(data) {
      data.sort(function(a, b) {
        return new Date(a) - new Date(b);
      });
    },
    formatDateToTime(date) {
      if (date) {
        let hours = ("0" + new Date(date).getHours()).slice(-2);
        let minutes = ("0" + new Date(date).getMinutes()).slice(-2);
        let seconds = ("0" + new Date(date).getSeconds()).slice(-2);
        return hours + ":" + minutes + ":" + seconds;
      } else {
        return "null";
      }
    },
    createListObject(data) {
      let obj = {};
      let dataKeys = Object.keys(data);
      this.listOfCalls = [];
      dataKeys.forEach((element, index) => {
        obj = {};
        let splitData = data[element].split(";");
        let splitEl = element.split("_");
        let chain = splitData[4].split(":");
        obj["code"] = element;
        obj["id"] = splitEl[1];
        obj["timeStart"] = splitData[0];
        obj["isAnswered"] = splitData[2];
        obj["timeEnd"] = splitData[1];
        obj["duration"] = splitData[3];
        // obj["origin"] = splitData[5];
        obj["chain"] = chain[1];
        this.listOfCalls.splice(index, 1, obj);
      });
    },
    async getPBXHost() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/pbx3cxHosts`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.pbxHosts = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getPBXData(event) {
      this.callData = [];
      if (event) {
        this.hostName = event;
      }
      try {
        const res = await axios
          .get(
            `${this.$cookie.getCookie("API")}/api/v1/pbx3cxCalls?code=${
              this.hostName
            }&timespan=${this.timeSpan}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            this.callData = res.data;
            this.isLoading = false;
            this.createListObject(res.data);
          });

      } catch (error) {
        this.errorHandling(error);
      }
    },
    reverseObj(obj) {
      return Object.keys(obj)
        .reverse()
        .reduce(function(result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    filterCallList() {
      if (this.search || this.search.length > 0) {
        return this.listOfCalls.filter((list) => {
          return String(list.id)
            .toLowerCase()
            .includes(String(this.search).toLowerCase());
        });
      } else {
        return this.listOfCalls;
      }
    },
  },
  mounted() {
    this.getPBXHost();
    this.getPBXData();
    this.timeSpan = this.getCurrentDate;
  },
  created() {
    this.interval = setInterval(() => this.getPBXData(), 10000);
  },
  computed: {
    getCurrentDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let currentDate = year + month + day;
      return currentDate;
    },
  },
  watch: {
    timeSpan: function(val) {
      this.getPBXData();
    },
  },
};
</script>
