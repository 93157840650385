<template>
  <div class="flex justify-between">
    <div class="inline-flex items-center gap-5">
      <v-date-picker
        class="inline-block h-full"
        color="teal"
        v-model="date"
        :max-date="new Date()"
        mode="date"
      >
        <template v-slot="{ togglePopover }">
          <button
            :class="[
              this.buttonSelected === 'day'
                ? 'encom_primary text-white'
                : 'encom_primary_text',
              'capitalize rounded items-center w-40 py-3 encom_primary_border text-sm font-medium hover:text-white encom_primary_hover focus:z-10 mt-1',
            ]"
            @click="togglePopover(), (buttonSelected = 'day')"
          >
            Day
            <!-- {{ $t("dashboards.day") }} -->
          </button>
        </template>
      </v-date-picker>
      <div class="content-center">
        <div>
          <label
            for="time-select"
            class="block text-xs font-medium text-gray-700"
            >{{ $t("selectTime") }}</label
          >
          <select
            name="time-select"
            id="time-select"
            v-model="hour"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
          >
            <option v-for="hour in hours" :key="hour" :value="hour">
              {{ hour }}
            </option>
          </select>
        </div>
        <div class="">
          <button
            class="py-0.5 px-2 bg-gray-200 rounded-md text-xs text-gray-800 hover:bg-gray-400 hover:text-gray-900"
            @click="hour = ''"
          >
            clear
          </button>
        </div>
      </div>
      <div class="ml-10 inline-flex items-center gap-2 -mt-2">
        <button
          type="button"
          class="px-4 py-2 border text-sm font-medium rounded-md shadow-sm body__header--button"
          @click="$emit('openAll')"
        >
          Open all
        </button>
        <button
          type="button"
          class="px-4 py-2 border text-sm font-medium rounded-md shadow-sm body__header--button"
          @click="$emit('closeAll')"
        >
          Close all
        </button>
      </div>
    </div>

    <div class="mr-10">
      <label for="role" class="block text-xs font-medium text-gray-700"
        >PBX Host</label
      >
      <div class="mt-0">
        <select
          v-model="hostname"
          type="number"
          name="role"
          id="role"
          class="shadow-sm encom_primary_border_focus block sm:text-sm border-gray-300 rounded-md"
        >
          <option v-for="host in pbxHosts" :key="host.id" :value="host.code">{{
            host.host_name
          }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
const date = new Date();
export default {
  props: ["pbxHosts"],
  data() {
    return {
      hostname: "",
      hour: "",
      date,
      buttonSelected: "",
      hours: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    };
  },
  mounted() {
    this.$emit("timeSelected", this.date, this.hour);
  },
  watch: {
    date: function(val) {
      this.$emit("timeSelected", val, this.hour);
    },
    hour: function(val) {
      let spliceHour = val.slice(0, 2);
      this.$emit("timeSelected", this.date, spliceHour);
    },
    hostname: function(val) {
      this.$emit("changeHost", val);
    },
  },
};
</script>

<style></style>
